import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcM67Do4jpu6y6tO0qYd9t0HJpbSmupatNJB%2FpF%2F8SG2oV%2Bvd%2FDnA8Bk8gurZX9T1zrA1XrfN%2B1Vvdp%2B69eT7%2B18v6h7Bdt6tZ1s2767%2FvTCAscWV%2B2y%2B7xezF43obHJl1296JrXDUyAHz8Zvmr6tl29fSk7Mvj%2FfdyI%2FtrOuv3ydd4H6OfT%2BmPlFaDXCsg5BXjxkioGSiv%2BGX8th69sGteYqSJjCkWlMl4Q9TMv6E1GZzoQrlCkvcKKBNIMiHEKSlSAVSGjmQ0QkldYlhmlNhLeclqMAixJOrqOV7YspVGBySRvngIzCohw2MbYTcIoypVGYl3C2MdR2Gaw2wAvD36%2FWYBFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmfXAeK%2BAFFhJbJIXBaffMXEXYyLOrOFru4yjPjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabm9zGAQsHxBZaZlD%2B8Bzo8S9axM3QmEY8DZJXRyssOeIpOjlWjTPCozwhyVD7DYJoWQFZBLj1I6elwntMmOJNKzEjaBGdT87LaeRtzLr1s%2FnrexpxPzXB%2BZpkCYpyMiMeMpG3MxRnJ7zouAcUh6QoFcQkozklTKohLQNOUNk7YaQmoSRCl3OaczVKtKNUqx7Xv9HedauWGMlGuW3GeauVimYocd%2FNOrktvpEi1qjLc7cAVxalOVS55cZ7yJOE6%2BVyt4kg1dKpTbp5gGqn%2BVCeTu%2FsqQLk5jA7XAdCL000AsuL0LoAhcdoHsFqcbgNYqbYL4KTaPoCXavcBCqn2EKCUaocApVR7DFBJtafAk218TDoAotAjDIAkBIn4WCiSCYBGSJINgFZKugDopKQP0OynXTOZtk9d2%2FN%2FTJeo9KURw5YK9qCX29FxmSbPs7%2Bw%2BpIuQG8OgJsD0OYAMqoqb%2BfOdiZrVweQ0U0z7UZNrt1oFqA4pe3vP%2F%2FHMbOGCwAA%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Freusables%2FQuestionContactBox%2FQuestionContactBox.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA7WVzW7jIBDH73kKpF7qAxZ280kuPfU1VgSIQ2uDd8CNs6u8%2BwocR8RxmmjV%2BpBYzDDzY2b%2BON22TbW0BP2dILRh%2FKMA02iBuSkNUPT0Fp71BKGaCaF0QdEng2eMf2WrCnaMZXXirRsDQgIGJlRjhz45Dz5C2bpkB4q2pWz9gv%2BnKPOv2L9joUByp4ymiJuyqXTwMtphq%2F5IirI0y2cgK79cKi3xTqpi57yhXw7eW1ap8jDEIEmfdDxRxaBQGm%2BMc6a6OqdJ1pPjJO3qlYV6nTY4U1NE6jay58F%2BQX6De%2FEA%2BCxZj2W7R9x15tTJS9shPszLD8DezNpDU0QihGlAGN2ULS%2BHZ1Ma%2FvETc%2BEd9qd9UxLTzW7TdUdywLRV53kyMBTAIhmuzOMOzOjOfEqI0zyR8HxH9HmIe9Yvqdt%2BfgpWj875iFZZqQqNlZOVpYhL7STcn0AbYywCxlXgMYZucm%2BkfG%2BsU9sD5kY7qV0XCVvHwJ31vQcf1f%2BOQF5KdfkFVpY%2FojN9S568Aet7WRvVwzvZOiwkN8C6hmqj5Xc0eRmN0H%2FGOs%2FepmSdxK5oGy0keIVFmVenu7DFeyXcjqLVvCvwayWFYshykFIjpgV6rpTuveZTUrdJ2JvGH6F71f4dSI9342cknw4S5KcE0c2Rp%2BeLY3B15GlkkM5JwLZmPEgIk5RE%2B764W6YP0i7mywHsy0U1OtkOSgFJLObj5PgPxpzI884HAAA%3D%22%7D"
export var container = 'fxum8s0';
export var containerBox = 'fxum8s6';
export var containerBoxIcon = 'fxum8s8';
export var containerBoxlogo = 'fxum8s7';
export var containerBoxlogoImg = 'fxum8s9';
export var containerLink = 'fxum8s5';
export var containerMainTitle = 'fxum8s2';
export var containerParagraph = 'fxum8s1';
export var containerSubTitle = 'fxum8s3';
export var titlePosition = 'fxum8s4';