import Image, { ImageProps } from "next/image";
import { ASPECT_RATIO, value2CssValue } from "@consts/aspectRatio";
import { TRadiusValue } from "@consts/roundCorners";
//import { SmartImageCustom } from "./SmartImageCustom";
import { SmartImageNextImage } from "./SmartImageNextImage";

type ExtendProps = {
  cropAspectRatio?: ASPECT_RATIO;
  radius?: TRadiusValue;
  bigImage?: boolean;
  full?: boolean;
};

export type SmartImageProps = ImageProps & ExtendProps;

const isSvg = (srcValue: any) => {
  const src = srcValue?.src ? srcValue.src : srcValue;
  const extension = src.split(".").pop() as string;
  return extension != undefined && ["svg", "svgz"].includes(extension.toLowerCase());
};

// pomer stran kontejneru
export const calculateCrosAspectRatioContainer = (
  cropAspectRatio: ASPECT_RATIO | undefined,
  imageWidth: number | undefined,
  imageHeight: number | undefined
) => {
  let cropAspectRatioImage = cropAspectRatio || ASPECT_RATIO.ORIGINAL;
  return cropAspectRatioImage == ASPECT_RATIO.ORIGINAL
    ? `${imageWidth}/${imageHeight}`
    : value2CssValue[cropAspectRatioImage];
};

export const SmartImage = (props: SmartImageProps) => {
  const { cropAspectRatio, radius, bigImage, alt, full, ...imageProps } = props;

  // svg obrazek
  if (isSvg(imageProps.src)) {
    return <Image {...props} alt={!alt ? "" : alt} unoptimized={true} />;
  }

  // bitmapovy obrazek
  //return <SmartImageCustom {...props} />;
  return <SmartImageNextImage {...props} />;
};
