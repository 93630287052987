import { useState, useEffect, useRef } from "react";
import Image, { ImageLoader } from "next/image";
import classNames from "classnames";
import { SmartImageProps, calculateCrosAspectRatioContainer } from "./SmartImage";
import { imageMediaServerUrl, isStaticImportImage } from "@utils/imageLoaders";
import {
  smartImageContainerLoader,
  smartImageContainerLoaderWide,
} from "./SmartImageNextImage.css";

const backendLoader: ImageLoader = ({ src, width }) => {
  return imageMediaServerUrl(src as string, width);
};

export const SmartImageNextImage = (props: SmartImageProps) => {
  const { cropAspectRatio, radius, bigImage, alt, full, ...imageProps } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [imageSizes, setImageSizes] = useState(100);

  // nastaveni loaderu pro nestaticky import
  if (!isStaticImportImage(imageProps.src)) {
    imageProps.loader = backendLoader;
  }

  // uprava image props
  const originalImageWidth = imageProps?.width ? (imageProps.width as number) : undefined;
  const originalImageHeight = imageProps?.height ? (imageProps.height as number) : undefined;
  imageProps.width = undefined;
  imageProps.height = undefined;
  imageProps.fill = true;

  // nastaveni sizes obrazku
  const updateImageSizes = () => {
    if (containerRef.current?.clientWidth) {
      setImageSizes(containerRef.current.clientWidth);
    }
  };

  // init sirky obrazku a event listener na resize
  useEffect(() => {
    updateImageSizes();
    window.addEventListener("resize", updateImageSizes);
    return () => {
      window.removeEventListener("resize", updateImageSizes);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        width: "100%",
        aspectRatio: calculateCrosAspectRatioContainer(
          cropAspectRatio,
          originalImageWidth,
          originalImageHeight
        ),
        borderRadius: radius || undefined,
      }}
    >
      <div
        className={classNames(
          smartImageContainerLoader,
          imageSizes > 1300 ? smartImageContainerLoaderWide : null
        )}
      ></div>
      <Image
        {...imageProps}
        alt={!alt ? "" : alt}
        sizes={`${imageSizes}px`}
        style={{
          objectFit: "cover",
          borderRadius: radius || undefined,
        }}
      />
    </div>
  );
};
