import React, { useRef } from "react";
import { Player, Product, PlayerLoaderRef } from "@czechtv/player";
import classNames from "classnames";
import { imageMediaServerUrl, isStaticImportImage } from "@utils/imageLoaders";
import { videoWrap } from "@components/blocks/VideoBlock/VideoBlock.css";

type TVideoIdType = "idec" | "versionId";

interface VideoBlockProps {
  videoID: string;
  videoIdType?: TVideoIdType;
  title?: null | string;
  subtitle?: null | string;
  previewImageUrl?: undefined | string;
}

// URI na default playlist
const defaultPlaylistUrl = "https://api.ceskatelevize.cz/video/v1/playlist-vod/v1";

// pouzit playlisty?
const withPlaylist = (videoIdType: TVideoIdType) => ["versionId"].includes(videoIdType);

// api pro zaznam informaci o browseru
const faqReportApiUrl = process.env.NEXT_PUBLIC_USER_BROWSER_INFO_API;

// omezeni kvality videa
//const maxAutoQuality = 720;
const maxAutoQuality = undefined;

export const VideoBlock = ({
  videoID,
  videoIdType = "idec",
  //title = null,
  //subtitle = null,
  previewImageUrl = undefined,
}: VideoBlockProps) => {
  const playerRef = useRef<PlayerLoaderRef>(null);

  // uprava cesty k nahledovemu obrazku
  const uppdatedPreviewImageUrl = previewImageUrl
    ? isStaticImportImage(previewImageUrl)
      ? previewImageUrl
      : imageMediaServerUrl(previewImageUrl)
    : undefined;

  // uprava props
  const updatedProps = {
    product: Product.ctdefault,
    fairplayAccessToken: "",
    widevineAccessToken: "",
    dev: false,
    idec: videoIdType == "idec" ? videoID : undefined,
    playerRef: playerRef,

    // versionId video (pridavane rucne do systemu)
    versionId: videoIdType == "versionId" ? videoID : undefined,

    // playlisty
    useNewPlaylist: withPlaylist(videoIdType),
    playlistVodUri: withPlaylist(videoIdType) ? defaultPlaylistUrl : undefined,

    // vlastni nahledovy obrazek
    previewImageUrl: uppdatedPreviewImageUrl,
    delayLoadingPlaylist: !!previewImageUrl,

    // omezeni kvality videa
    maxAutoQuality: maxAutoQuality,

    // autoplay
    autoplay: false,

    faqReportApiUrl: faqReportApiUrl,
  };

  return (
    <div className={classNames(videoWrap)}>
      <Player {...updatedProps} />
    </div>
  );
};
