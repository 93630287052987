import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcM67Do4jpu6y6tO0qYd9t0HJpbSmupatNJB%2FpF%2F8SG2oV%2Bvd%2FDnA8Bk8gurZX9T1zrA1XrfN%2B1Vvdp%2B69eT7%2B18v6h7Bdt6tZ1s2767%2FvTCAscWV%2B2y%2B7xezF43obHJl1296JrXDUyAHz8Zvmr6tl29fSk7Mvj%2FfdyI%2FtrOuv3ydd4H6OfT%2BmPlFaDXCsg5BXjxkioGSiv%2BGX8th69sGteYqSJjCkWlMl4Q9TMv6E1GZzoQrlCkvcKKBNIMiHEKSlSAVSGjmQ0QkldYlhmlNhLeclqMAixJOrqOV7YspVGBySRvngIzCohw2MbYTcIoypVGYl3C2MdR2Gaw2wAvD36%2FWYBFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmfXAeK%2BAFFhJbJIXBaffMXEXYyLOrOFru4yjPjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabm9zGAQsHxBZaZlD%2B8Bzo8S9axM3QmEY8DZJXRyssOeIpOjlWjTPCozwhyVD7DYJoWQFZBLj1I6elwntMmOJNKzEjaBGdT87LaeRtzLr1s%2FnrexpxPzXB%2BZpkCYpyMiMeMpG3MxRnJ7zouAcUh6QoFcQkozklTKohLQNOUNk7YaQmoSRCl3OaczVKtKNUqx7Xv9HedauWGMlGuW3GeauVimYocd%2FNOrktvpEi1qjLc7cAVxalOVS55cZ7yJOE6%2BVyt4kg1dKpTbp5gGqn%2BVCeTu%2FsqQLk5jA7XAdCL000AsuL0LoAhcdoHsFqcbgNYqbYL4KTaPoCXavcBCqn2EKCUaocApVR7DFBJtafAk218TDoAotAjDIAkBIn4WCiSCYBGSJINgFZKugDopKQP0OynXTOZtk9d2%2FN%2FTJeo9KURw5YK9qCX29FxmSbPs7%2Bw%2BpIuQG8OgJsD0OYAMqoqb%2BfOdiZrVweQ0U0z7UZNrt1oFqA4pe3vP%2F%2FHMbOGCwAA%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fblocks%2FDirectorsStrip%2FDirectorsStrip.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA71VwW7iMBC98xVzLAdHdghQzGX%2FZOUmTrA2sV3HFNKKf1%2FFhDQ4JiBUVQghzXie37x5HqL88yM%2F%2FMPwNQOomCmEpICB7a2CD2ZeEPpLNpXZMUb03IW3s9MsOlcRV5UraVEtPjkFEq2XhlfbGUApJEc7LoqdpRB3QXc0Z5UoG%2BrBJ%2FP2QKpKZfxU41JncsgqTQHr4yD0pqxVlV9FlCvTLMuELCjg7bBDfXTfUckZuu8wvtZlDFiK2qLaNiVHttGcglSSt4lM1LpkDYXCiKwNtL%2FI8kqXzHJk1KGmQHLTp1ptu7An88KRuK5PVbmv5ACC6aAAPUjyEyDL0cBD0ybRorfBxMSxm4%2FlR4tYKQpJIeXScrP1FQ%2Bagmy88Y7tOiC%2BGjs1aNSBgSeYL3%2FFq9PKBJzbfgY9r33v9plXl3H4GU%2BVYVYo%2BW3d23r3CJtvhBHDrotz90E3%2Fal4JhjUqeFcApMZvFRCooPI7I4CwXGij3N3w9WiuRpgHMWXSfnLJhokuLXcoFqz1AmLcIQHdXf30eku21WCPbK4Izs98X2HP%2Brxzop6n18c9Qy3RXfJ7T1w2QXhbfDus04eApwGlT7ocjxuEpFb8ybRQwPF%2Fi0rT%2FDx3133oh6Rer16fUrqoTohWQNyGb%2BRTXd46uHpB%2B0ceHyLCS4XF5%2F%2BAxkGXk5ICAAA%22%7D"
export var btnContainer = 'fzvfwk9';
export var directorList = 'fzvfwk2';
export var directorList3 = 'fzvfwk4';
export var directorList4 = 'fzvfwk3';
export var itemTxt = 'fzvfwk5';
export var itemTxt_a = 'fzvfwk8';
export var itemTxt_h3 = 'fzvfwk6';
export var itemTxt_p = 'fzvfwk7';
export var mainContainer = 'fzvfwk0';
export var mainHeadline = 'fzvfwk1';