import classNames from 'classnames';
import { SmartImage } from '@components/reusables/SmartImage/SmartImage';
import imagePraha  from '../../images/studioPraha.jpg'
import imageBrno  from '../../images/studioBrno.jpg'
import imageOstrava  from '../../images/studioOstrava.jpg'
import {
  introBox,
  introBoxContactStudios,
  introBoxContactStudiosImage,
  introBoxEnd,
  introBoxItemLink,
  introBoxItemLinkNoLink,
  introBoxLink,
  introBoxParagraph,
  mainHeader,
  subHeader
} from "@components/core/PageTypes/UneditablePage/pages/GlobalContactsPage/components/GlobalContactStudias/GlobalContactStudias.css";

export const GlobalContactStudias = () => {
  return (
      <article >
        <div className={classNames(introBox)} >
          <h2 className={classNames(mainHeader, 'bigMB')}>Kontakty na televizní studia</h2>
            <div className={classNames(introBoxContactStudios)}>
              <div>
                <SmartImage src={imagePraha.src} alt={'Televizní studio Praha'} width={565} height={316} className={classNames(introBoxContactStudiosImage)} />
                <h3 className={classNames(subHeader)}>Praha</h3>
                <p className={classNames(introBoxParagraph)}>
                  Na Hřebenech II 1132/4, 140 70 Praha 4
                </p>
                <a href="https://www.ceskatelevize.cz/vse-o-ct" className={classNames(introBoxLink, introBoxItemLink, introBoxItemLinkNoLink)} target="_blank">www.ceskatelevize.cz/vse-o-ct</a>
                <a href="tel:+420261136113" className={classNames(introBoxItemLink)}>261 136 113</a>
              </div>
              <div>
                <SmartImage src={imageBrno.src} alt={'Televizní studio Brno'} width={565} height={316} className={classNames(introBoxContactStudiosImage)} />
                <h3 className={classNames(subHeader)}>Brno</h3>
                <p className={classNames(introBoxParagraph)}>
                Trnkova 117, 628 00 Brno
                </p>
                <a href="https://www.ceskatelevize.cz/brno" className={classNames(introBoxLink, introBoxItemLink, introBoxItemLinkNoLink)} target="_blank">www.ceskatelevize.cz/brno</a>
                <a href="tel:+420516609111" className={classNames(introBoxLink, introBoxItemLink)}>516 609 111</a>
              </div>
              <div>
                <SmartImage src={imageOstrava.src} alt={'Televizní studio Ostrava'} width={565} height={316} className={classNames(introBoxContactStudiosImage)} />
                <h3 className={classNames(subHeader)}>Ostrava</h3>
                <p className={classNames(introBoxParagraph)}>
                  Dvořákova 18, 728 20 Ostrava 1
                </p>
                <a href="https://www.ceskatelevize.cz/ostrava" className={classNames(introBoxLink, introBoxItemLink, introBoxItemLinkNoLink)} target="_blank">www.ceskatelevize.cz/ostrava</a>
                <a href="tel:+420556448111" className={classNames(introBoxLink, introBoxItemLink)}>556 448 111</a>
              </div>
            </div>
        </div>
        <div className={classNames(introBox)} >
          <h2 className={classNames(mainHeader, 'bigMB')}>Regiony</h2>
          <h3 className={classNames(subHeader)}>Region střed (Praha, střední Čechy)</h3>
          <p className={classNames(introBoxParagraph)}>
            tel.: <a className={classNames(introBoxLink)} href="tel:261135039">+420 261 135 039</a> e-mail: <a className={classNames(introBoxLink)} href="mailto:redakce.strednicechy@ceskatelevize.cz">redakce.strednicechy@ceskatelevize.cz</a>
          </p>
          <h3 className={classNames(subHeader)}>Region západ (Plzeň, Klatovy, Karlovy Vary)</h3>
          <p className={classNames(introBoxParagraph)}>
            tel.: <a className={classNames(introBoxLink)} href="tel:+420261134850">+420 261 134 850</a> e-mail: <a className={classNames(introBoxLink)} href="mailto:redakce.plzen@ceskatelevize.cz">redakce.plzen@ceskatelevize.cz</a>, <a className={classNames(introBoxLink)} href="mailto:redakce.vary@ceskatelevize.cz">redakce.vary@ceskatelevize.cz</a>
          </p>
          <h3 className={classNames(subHeader)}>Region východ (Pardubice, Hradec Králové)</h3>
          <p className={classNames(introBoxParagraph)}>
            tel.: <a className={classNames(introBoxLink)} href="tel:+420261134812">+420 261 134 812</a> e-mail: <a className={classNames(introBoxLink)} href="mailto:redakce.pardubice@ceskatelevize.cz">redakce.pardubice@ceskatelevize.cz</a>, <a className={classNames(introBoxLink)} href="mailto:redakce.hradec@ceskatelevize.cz">redakce.hradec@ceskatelevize.cz</a>
          </p>
          <h3 className={classNames(subHeader)}>Region jih (České Budějovice, Jihlava, Tábor)</h3>
          <p className={classNames(introBoxParagraph)}>
            tel.: <a className={classNames(introBoxLink)} href="tel:+420261134861">+420 261 134 861</a> e-mail: <a className={classNames(introBoxLink)} href="mailto:redakce.budejovice@ceskatelevize.cz">redakce.budejovice@ceskatelevize.cz</a>, <a className={classNames(introBoxLink)} href="mailto:redakce.jihlava@ceskatelevize.cz">redakce.jihlava@ceskatelevize.cz</a>
          </p>
          <h3 className={classNames(subHeader)}>Region sever (Ústí nad Labem, Liberec)</h3>
          <p className={classNames(introBoxParagraph)}>
            tel.: <a className={classNames(introBoxLink)} href="tel:+420703401236">+420 703 401 236</a> e-mail: <a className={classNames(introBoxLink)} href="mailto:redakce.usti@ceskatelevize.cz">redakce.usti@ceskatelevize.cz</a>, <a className={classNames(introBoxLink)} href="mailto:redakce.liberec@ceskatelevize.cz">redakce.liberec@ceskatelevize.cz</a>
          </p>
        </div>
        <div className={classNames(introBoxEnd)} >Údaje zveřejněné podle zákona 231/2001 Sb. a 132/2010 Sb.<br/><br/>Provozovatelem televizního vysílání a poskytovatelem audiovizuálních mediálních služeb na vyžádání je Česká televize, Kavčí hory, 140 70 Praha 4, IČ 00027383.<br/><br/>
        Orgánem dohledu je Rada pro rozhlasové a televizní vysílání.</div>
      </article>
  )
}
